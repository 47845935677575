












































































import Vue from 'vue'
import { getOssUrl } from '@/api/common'
import { useTable } from '@/components/table/table-hook'
import { getCouponList, editCoupon, delCoupon } from '@/api/coupon'

export default Vue.extend({
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			isPrice: 1,
			editCoupon,
			...useTable({
				name: '优惠券',
				formdata: {
					/** 名称 */
					id: '',
					type_id: '',
					name: '',
					icon: '',
					price: '',
					discount: '',
					valid_time: '',
				},
				getData: (query) => getCouponList(query).then((res) => res.data),
				delData: (row) => delCoupon({ id: row.id }),
			}),
			checkList: [] as any,
			menuList: [] as any,
			current: '' as any,
		}
	},
	inject: ['oss56'],
	created() {
		this.getOssUrl()
		this.tableGetData()
	},
	methods: {
		checkNumberFloat(value) {
			// 去掉数字和.以外的内容
			value = value.replace(/[^\d.]/g, '')
			// 不允许.开头
			value = value.replace(/^\./g, '')
			// 去掉连续的.
			value = value.replace(/\.{2,}/g, '.')
			// 只允许一个点，第一个会匹配到第一个点，第二个会匹配到剩余的点
			// 因为是输入框，最多会呈现到‘1.23.’这种方式
			// 注意第一个点是字符串方式匹配，第二个是正则匹配
			value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			//两位小数，‘\.(\d\d)’匹配到两位数
			value = value.replace(/^(-)*(\d+)\.(\d).*$/, '$1$2.$3')
			if (value > 10) {
				value = 10
			}
			if (value < 0) {
				value = 0
			}
			return value
		},
		changType(e) {
			if (e == 4) {
				this.isPrice = 2
			} else {
				this.isPrice = 1
			}
		},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			row.price = row.price * 100
			row.discount = row.discount / 10
			let base_url_length = this.baseUrl.split('').length
			if (row.icon.indexOf(this.baseUrl) !== -1) {
				row.icon = row.icon.slice(base_url_length)
			}
			return editCoupon(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		editrow(row) {
			console.log(row, '-----')
			if (row.type_id == 4) {
				this.isPrice = 2
			} else {
				this.isPrice = 1
			}
			row = JSON.parse(JSON.stringify(row))
			row.price = row.price / 100
			row.discount = row.discount * 10
			;(this as any).$refs.formDialogRef.open(row)
		},
		openpermi(row) {
			this.checkList = row.menu_ids
			this.current = row
		},
		savePermi() {},
	},
})
