





























































































































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import SelectActivityType from '@/components/select/select-activity-type.vue'
import SelectDisCountType from '@/components/select/select-discount-type.vue'
import { getActivityList, editActivity, delActivity } from '@/api/activity'
import { getOssUrl } from '@/api/common'
export default Vue.extend({
	components: {
		RichTextEditor,
		SelectActivityType,
		SelectDisCountType,
	},
	inject: ['oss23'],
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			que: [
				{ id: 1, name: '白银会员' },
				{ id: 2, name: '金卡会员' },
				{ id: 3, name: '铂金会员' },
				{ id: 4, name: '钻石会员' },
				{ id: 5, name: '永久钻石会员' },
			],

			editActivity,
			...useTable({
				name: '活动',
				formdata: {
					/** 名称 */
					id: '',
					name: '',
					cover_image: '',
					flex_num: '',
					route: '',
					detail: '',
					type_id: '',
					discount_type_id: 1,
					few_fold: '',
					full: '',
					reduction: '',
					is_first_order: '0',
					vip_card_ids: [],
					start_time: '', //开始活动有效期
					end_time: '', //结束活动有效期
					daily_start_time: '', //活动每一天开始时间
					daily_end_time: '', //活动每一天结束时间
					type: 2,
				},
				getData: (query) => getActivityList(query).then((res) => res.data),
				delData: (row) => delActivity({ id: row.id }),
			}),
			checkList: [] as any,
			menuList: [] as any,
			current: '' as any,
		}
	},
	created() {
		this.getOssUrl()
		this.tableGetData()
	},
	methods: {
		changefirst(e) {
			if (e) {
				this.table_formdata.is_first_order = '1'
			} else {
				this.table_formdata.is_first_order = '0'
			}
		},
		openpermi(row) {
			this.checkList = row.menu_ids
			this.current = row
		},
		savePermi() {},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			row.vip_card_ids = JSON.stringify(row.vip_card_ids)
			row.few_fold = row.few_fold / 10
			row.full = row.full * 100
			row.reduction = row.reduction * 100
			let base_url_length = this.baseUrl.split('').length
			if (row.cover_image.indexOf(this.baseUrl) !== -1) {
				row.cover_image = row.cover_image.slice(base_url_length)
			}
			return editActivity(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		editrow(row) {
			row = JSON.parse(JSON.stringify(row))
			row.few_fold = row.few_fold * 10
			row.full = row.full / 100
			row.reduction = row.reduction / 100
			;(this as any).$refs.formDialogRef.open(row)
		},
	},
})
