import request, { ResponsePageableData } from '@/libs/request'

//角色列表
export const getRoleList = (data?) => request<ResponsePageableData>({ url: '/adminapi/AdminRole/getRoleList', data })

//编辑角色
export const editRole = (data?) => request<ResponsePageableData>({ url: '/adminapi/AdminRole/editRole', data })

//删除角色
export const delRole = (data?) => request<ResponsePageableData>({ url: '/adminapi/AdminRole/delRole', data })

//获取菜单列表
export const getMenuList = (data?) => request<ResponsePageableData>({ url: '/adminapi/AdminRole/getMenuList', data })

//设置角色权限
export const setRoleMenus = (data?) => request<ResponsePageableData>({ url: '/adminapi/AdminRole/setRoleMenus', data })

//获取管理员列表
export const getAdminList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Admin/getAdminList', data })

//编辑管理员
export const editAdmin = (data?) => request<ResponsePageableData>({ url: '/adminapi/Admin/editAdmin', data })

//删除管理员
export const delAdmin = (data?) => request<ResponsePageableData>({ url: '/adminapi/Admin/delAdmin', data })
