






























































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import SelectRole from '@/components/select/select-role.vue' //订餐商品类型选择器
import SelectHotel from '@/components/select/select-hotel.vue'
import { getAdminList, editAdmin, delAdmin, getMenuList, getRoleList } from '@/api/permission-role'

export default Vue.extend({
	components: { SelectRole, SelectHotel },
	data() {
		return {
			editAdmin,
			...useTable({
				name: '角色',
				formdata: {
					/** 名称 */
					id: '',
					name: '',
					menu_ids: [] as any,
				},
				getData: (query) => getAdminList(query).then((res) => res.data),
				delData: (row) => delAdmin({ id: row.id }),
			}),
			checkList: [] as any,
			menuList: [] as any,
			roleList: [] as any,
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		getRoleLists() {
			getRoleList().then((res) => {
				this.roleList = res.data.rows
			})
		},
	},
})
