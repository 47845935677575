<template>
	<div class="rich-text-editor-wapper">
		<div class="tool-bar" ref="toolbar"></div>
		<div class="containner" ref="containner"></div>
	</div>
</template>
<script>
import WangEditor from 'wangeditor'
import { uploadImage } from '@/api/common'
export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '请输入正文',
		},
	},
	data() {
		return {
			editor: null,
		}
	},
	watch: {
		value(val) {
			if (this.editor && val != this.editor.txt.html()) {
				this.editor.txt.html(val)
			}
		},
	},
	methods: {
		initEditor() {
			this.editor = new WangEditor(this.$refs.toolbar, this.$refs.containner)
			// 粘贴后不过滤内容样式
			this.editor.config.pasteFilterStyle = false
			// 设置placeholder
			this.editor.config.placeholder = this.placeholder
			// 监听用户输入变化
			this.editor.config.onchange = (html) => {
				// html 即变化之后的内容
				this.$emit('input', html)
			}
			this.editor.config.customUploadImg = (files, insert) => {
				if (!files.length) return

				// const loading = this.$Message.loading({
				//     content: "上传中...",
				//     duration: 0,
				// });
				let upload_promise_list = []
				files.forEach((file) => {
					let formdata = new FormData()
					formdata.append('file', file)
					upload_promise_list.push(
						uploadImage(formdata).then((res) => {
							console.log(file, 'file')
							if (res.code == 0) {
								return res.data.url
							} else {
								throw res.msg
							}
						})
					)
				})
				Promise.all(upload_promise_list)
					.then((res_list) => {
						res_list.forEach((url) => {
							insert(url)
							this.editor.txt.append('<br/>')
						})
					})
					.catch((e) => {
						console.log(e)
						// this.$Message.error(e);
					})
					.finally(() => {
						// loading();
					})

				// let file_size = file.size;
				// let file_ext = file.name.substring(
				//     file.name.lastIndexOf(".") + 1,
				//     file.name.length
				// );

				// files 是 input 中选中的文件列表
				// insert 是获取图片 url 后，插入到编辑器的方法
			}
			this.editor.create()
		},
		uploadImage() {},
	},
	mounted() {
		this.initEditor()
		this.editor.txt.html(this.value)
	},
}
</script>
<style scoped lang="scss">
.rich-text-editor-wapper {
	border: 1px solid #dadada;
	.tool-bar {
		border-bottom: 1px solid #e2e2e2;
	}
	.containner {
		height: 400px;
	}
}
</style>
