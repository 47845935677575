



















































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import { getNoticeLogList, noticeToAll } from '@/api/message'

export default Vue.extend({
	components: {
		RichTextEditor,
	},
	data() {
		return {
			que: [
				{ id: 1, name: '白银会员' },
				{ id: 2, name: '金卡会员' },
				{ id: 3, name: '铂金会员' },
				{ id: 4, name: '钻石会员' },
				{ id: 5, name: '永久钻石会员' },
			],
			...useTable({
				name: '消息',
				formdata: {
					/** 名称 */
					notice_type: 1,
					content: '',
					vip_card_ids: [],
					is_app_push: 0,
				},
				getData: (query) => getNoticeLogList(query).then((res) => res.data),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		changefirst(e) {
			if (e) {
				this.table_formdata.is_app_push = 1
			} else {
				this.table_formdata.is_app_push = 0
			}
		},
		onSubmit(row) {
			row.vip_card_ids = JSON.stringify(row.vip_card_ids)
			console.log(row)
			return noticeToAll(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
	},
})
