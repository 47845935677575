








































































































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import SelectActivityType from '@/components/select/select-activity-type.vue'
import SelectDisCountType from '@/components/select/select-discount-type.vue'
import { getBannerList, editBanner, delBanner } from '@/api/activity_banner'
import { getActivityList } from '@/api/activity'
import { getOssUrl } from '@/api/common'
export default Vue.extend({
	components: {
		RichTextEditor,
		SelectActivityType,
		SelectDisCountType,
	},
	inject: ['oss179'],
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			que: [
				{ id: 1, name: '白银会员' },
				{ id: 2, name: '金卡会员' },
				{ id: 3, name: '铂金会员' },
				{ id: 4, name: '钻石会员' },
				{ id: 5, name: '永久钻石会员' },
			],

			editBanner,
			...useTable({
				name: '活动',
				formdata: {
					/** 名称 */
					id: '',
					name: '',
					cover_image: '',
					detail: '',
					activity_id: '',
				},

				getData: (query) => getBannerList(query).then((res) => res.data),
				delData: (row) => delBanner({ id: row.id }),
			}),
			options: [] as any,
			checkList: [] as any,
			menuList: [] as any,
			current: '' as any,
			arrhuodong: [] as any,
		}
	},
	created() {
		this.getOssUrl()
		this.tableGetData()
		this.getadd()
	},
	methods: {
		arrchange(e) {},
		getadd() {
			getActivityList().then((res) => {
				// console.log(res.data.rows)
				this.arrhuodong = res.data.rows
				// console.log(this.arrhuodong);
				this.arrhuodong.map((res) => {
					this.options.push({
						value: res.id,
						label: res.name,
					})
				})

				console.log(this.options)
			})
		},

		openpermi(row) {
			this.checkList = row.menu_ids
			this.current = row
		},
		savePermi() {},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			row.vip_card_ids = JSON.stringify(row.vip_card_ids)
			row.few_fold = row.few_fold / 10
			row.full = row.full * 100
			row.reduction = row.reduction * 100
			let base_url_length = this.baseUrl.split('').length
			if (row.cover_image.indexOf(this.baseUrl) !== -1) {
				row.cover_image = row.cover_image.slice(base_url_length)
			}
			return editBanner(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		editrow(row) {
			row = JSON.parse(JSON.stringify(row))
			row.few_fold = row.few_fold * 10
			row.full = row.full / 100
			row.reduction = row.reduction / 100
			;(this as any).$refs.formDialogRef.open(row)
		},
	},
})
